<template>
  <div class="backclick">
    <div class="logo-container">
      <a href="https://www.clickdefense.io">
        <img class="imgLogInCD" src="@/assets/images/logo/clickdefense-logo-small-dark.png">
      </a>
    </div>
    <div data-v-2b7575d8="" errormessage="msgAuth">
      <!-- Step 1 - Enter Google account: -->
      <div v-if="registerStep==='1'" data-test="sign-in-section" class="Form__formSection___3tqxz">
        <div data-test="sign-in-header-section" class="Section__sectionHeader___13iO4">Empieza en ClickDefense con una cuenta gratuita</div>
        <div class="form-help-text text-center">
          <p>Enlaza tu cuenta de anuncios y <strong>analiza tus clics fraudulentos completamente gratis</strong>.<br /> Sin necesidad de tarjeta de crédito.</p>
        </div>
        <div data-test="sign-in-body-section" class="Section__sectionBody___3DCrX">
          <div class="Form__formField___2DWhT">
            <div class="gAccInput">
              <div class="Input__inputLabel___nQolz">Introduce tu <strong>cuenta de Google Ads</strong> (debes tener permiso de administrador) *</div>
              <input v-model="gAccountInput" @keyup="validateStep1" placeholder="Formato: XXX-XXX-XXXX" autofocus="autofocus" data-test="username-input" class="Input__input___2Sh1s">
            </div>
          </div>
        </div>
        <div data-test="sign-in-footer-section" class="Section__sectionFooter___1QlDn">
          <span class="Section__sectionFooterPrimaryContent___36Gb1">
            <button data-test="sign-in-sign-in-button" class="Button__button___1FrBC btn-continue" @click="nextStep1" v-bind:class="{butdisabled:!validStep1Form}">Continuar</button>
          </span>
          <span class="Section__sectionFooterSecondaryContent___3cjOa">¿Ya tienes cuenta de ClickDefense? <a data-test="sign-in-create-account-link" class="Anchor__a___3JUCG font-weight-bold" @click="btnAlreadyCdAcc">Iniciar sesión</a>
          </span>
        </div>
      </div>
      <!-- Step 2 - Confirm Google account: -->
      <div v-if="registerStep==='2'" data-test="sign-in-section" class="Form__formSection___3tqxz">
        <div data-test="sign-in-header-section" class="Section__sectionHeader___13iO4">Google Ads te ha enviado un correo para enlazar ClickDefense</div>
        <div class="form-help-text text-center">
          <p>Abre el correo y <strong>sigue los pasos para aceptar la solicitud de enlace</strong>.</p>
          <p class="lessSpaceSignUp"><strong>Pasados unos segundos</strong>, pulsa aquí el botón "CONTINUAR". </p>
        </div>
        <div data-test="sign-in-footer-section" class="Section__sectionFooter___2QlDn centerButton">
          <span class="Section__sectionFooterPrimaryContent___36Gb1">
            <button data-test="sign-in-sign-in-button" class="Button__button___1FrBC btn-continue btn-back" @click="nextStep2" v-bind:class="{butdisabled:!validStep2Form}">Continuar</button>
          </span>
        </div>
      </div>
      <div v-if="registerStep==='2'" data-test="sign-in-section" class="Form__formSection___3tqxz greenContainer">
        <div class="textGAccCont">
          <div class="GAccContTitle">¿Por qué tengo que enlazar mi cuenta?</div>
          <div class="GAccContText">Para analizar tu tráfico y protegerte de clics fraudulentos, nuestro servidor tiene que gestionar los parámetros de seguimiento y la lista de exclusión de tu cuenta.<br /> Puedes volver al estado inicial <strong>en cualquier momento</strong> dándote de baja en ClickDefense.</div>
        </div>
      </div>
      <!-- Step 2a - Confirm Google account: -->
      <div v-if="registerStep==='2a'" data-test="sign-in-section" class="Form__formSection___3tqxz">
        <div data-test="sign-in-header-section" class="Section__sectionHeader___13iO4">¡Opss! Ha ocurrido un problema:</div>
        <div v-if="nAttempts < 2" class="form-help-text text-center">
          <p>Verifica si la cuenta de Google Ads ({{gAccountInput}}) es correcta y si tienes permiso de administrador.</p>
        </div>
        <div v-else class="form-help-text text-center">
          <p>Tu cuenta no está siendo verificada, contacta con nosotros en: <a href="mailto:soporte@clickdefense.io">soporte@clickdefense.io</a></p>
        </div>
        <div data-test="sign-in-footer-section" class="Section__sectionFooter___2QlDn backButton">
          <span class="Section__sectionFooterPrimaryContent___36Gb1">
            <button v-if="nAttempts < 2" data-test="sign-in-sign-in-button" class="Button__button___1FrBC btn-back" @click="nextStep2a">Volver</button>
          </span>
        </div>
      </div>
      <!-- Step 2 modal -->
      <b-modal v-model="showModalStep2" title="Aún no tenemos acceso a tu cuenta de Google Ads" header-class="modal-header" body-class="modal-body" footer-class="modal-footer">
        <b-container fluid>
          <p>Para continuar con el registro sigue estos pasos:</p>
          <ul>
            <li>
              <strong>1.</strong>
              <span>Abre el correo asociado con tu cuenta de Google Ads.</span>
            </li>
            <li>
              <strong>2.</strong>
              <span>Pulsa en el enlace del mensaje de Google Ads y sigue las instrucciones para enlazar la cuenta. (Puede tardar unos minutos).</span>
            </li>
            <li>
              <strong>3.</strong>
              <span>Vuelve al registro y pulsa en el botón "CONTINUAR".</span>
            </li>
            <p>Si ya has seguido los pasos, espera unos segundos y vuelve a pulsar "CONTINUAR".</p>
          </ul>
        </b-container>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button variant="primary" size="sm" class="float-right modal-button" @click="showModalStep2=false">Cerrar</b-button>
          </div>
        </template>
      </b-modal>
      <!-- Step 3 - Enter user data: -->
      <div v-if="registerStep==='3'" data-test="sign-up-section" class="Form__formSection___3tqxz">
        <div data-test="sign-up-header-section" class="Section__sectionHeader___13iO4">Elige los datos de acceso a tu cuenta</div>
        <div data-test="sign-up-body-section" class="Section__sectionBody___3DCrX">
          <div signupfield="name" class="Form__formField___2DWhT">
            <div>
              <div class="Input__inputLabel___nQolz">¿Cómo te llamamos? *</div>
              <input v-model="nameInput" @keyup="validateStep3" placeholder="Escribe tu nombre" data-test="sign-up-non-phone-number-input" type="string" class="Input__input___2Sh1s">
            </div>
          </div>
          <div signupfield="email" class="Form__formField___2DWhT">
            <div>
              <div class="Input__inputLabel___nQolz">¿Dónde te enviamos la confirmación? *</div>
              <input v-model="emailInput" @keyup="validateStep3" placeholder="Escribe tu correo electrónico" data-test="sign-up-non-phone-number-input" type="string" class="Input__input___2Sh1s" v-bind:class="{'input-error':inpErrEmail}" v-on:focus="inpErrEmail=false">
            </div>
          </div>
          <div signupfield="password" class="Form__formField___2DWhT">
            <div>
              <div class="Input__inputLabel___nQolz">¿Qué contraseña quieres utilizar? * <span class="input-format-msg">(Mínimo 8 carácteres)</span></div>
              <input v-model="passwordInput" @keyup="validateStep3" placeholder="Escribe tu contraseña" data-test="sign-up-non-phone-number-input" type="password" class="Input__input___2Sh1s">
            </div>
          </div>
        </div>
        <div class="textar">
          <div id="conditions">
            <table width="100%" cellspacing="0" cellpadding="0" class="mobile-shell">
              <tr>
                <td class="p30-15" valing="top">
                  <ul>
                    <p class="element" style="color:#000000; font-family:'Lato', Arial,sans-serif; font-size:14px; line-height:28px;">
                      Los siguientes son los <strong>Términos y Condiciones</strong> para el uso del servicio de
                      ClickDefense descrito en este documento. Este es un servicio proporcionado por
                      ClickDefense desde los sitios web clickdefense.io y .cc que se denominará "ClickDefense” o
                      "nosotros" y el servicio proporcionado en clickdefense.io y .cc que se denominará en
                      adelante "el Servicio". Por favor lea estos términos cuidadosamente. Al completar el
                      proceso de registro y/o utilizar el servicio, usted declara que tiene derecho a una
                      cuenta y que acepta regirse por todos los Términos y Condiciones de estas
                      condiciones de servicio ("Acuerdo"). El servicio se le ofrece a condición de que
                      acepte sin modificaciones de los términos, condiciones y avisos contenidos en este
                      documento.</p>
                  </ul>
                </td>
              </tr>
            </table>
            <!-- END Intro -->
            <!-- Article Image On The Right -->
            <table style="width: 100%; display: block; overflow-x: auto;">
              <tr>
                <td class="p30-15" valign="top" style="padding: 50px 0px 0px 0px; margin: 50px;">
                  <ul>
                    <li id="title"><strong>1. Definiciones:</strong></li>
                    <ul id="table">
                      <p class="element">"Cuenta": se refiere a la cuenta de facturación del Servicio.</p>
                      <p class="element">"Datos del Cliente": significa los datos sobre las
                        características y la actividad de los visitantes de su sitio web recogido
                        mediante el enlace de su cuenta de Google Ads o el uso del Código de Seguimiento
                        que se almacenan en los Servidores y son analizados por el software de
                        procesamiento. Incluyendo los datos de registro considerados como datos personales bajo el
                        GDPR.</p>
                      <p class="element">"Clic fraudulento": es un tipo de actividad que se produce en la
                        publicidad de pago por clic en Internet en la que una persona o un sistema
                        informático hace clic en un anuncio con el fin de generar cargos adicionales
                        para el anunciante.</p>
                      <p class="element">"Código de Seguimiento": se refiere al código propietario de
                        ClickDefense que se instala en la página web con el fin de recopilar datos de
                        los clics realizados.</p>
                      <p class="element">"Software de procesamiento": el software propietario de
                        ClickDefense, el Código de Seguimiento y todas las actualizaciones del mismo,
                        que analiza los Datos de los Clientes y genera los informes.</p>
                      <p class="element">“Informe”: significa el análisis resultante de los Datos que se
                        muestra en ClickDefense.</p>
                      <p class="element">"Servidores": los servidores controlados por el Servicio en
                        los que los Datos del Cliente se almacenan y analizan para proporcionar los
                        informes.</p>
                      <p class="element">"Suscripción": significa los términos de facturación acordados
                        para su Cuenta, entre usted y ClickDefense</p>
                      <p class="element">“GDPR”: significa el Reglamento General de Protección de Datos
                        (UE) 2016/679.</p>
                    </ul>
                  </ul>
                  <!--Segunda parte-->
                  <ul>
                    <li id="title"> <strong>2. Cuenta de usuario:</strong></li>
                    <ul id="table">
                      <p class="element">Para registrarse en el Servicio, debe completar el proceso de
                        registro proporcionado en el Servicio con información actual, completa y
                        precisa, tal como se le pide en el formulario de registro, incluyendo su cuenta
                        de Google Ads, su nombre, una dirección de correo electrónico y una contraseña. Usted
                        será en todo momento será responsable de mantener la seguridad de su cuenta y
                        contraseña: debe proteger sus contraseñas y es totalmente responsable del uso
                        propio y de terceros de sus cuentas. ClickDefense no puede y no será responsable
                        de ninguna pérdida y/o daño como resultado de su incumplimiento de esta
                        obligación de seguridad.</p>
                      <p class="element">ClickDefense, a su entera discreción, puede aceptar o rechazar
                        por cualquier razón a cualquiera como usuario del Servicio.</p>
                      <p class="element">No puede utilizar el Servicio para ningún propósito ilegal o no
                        autorizado, ni violar las leyes aplicables (incluidas, entre otras, las leyes de
                        derechos de autor). Usted es el único responsable de todas y cada una de las
                        actividades relacionadas con su Cuenta. Usted acepta notificar a ClickDefense
                        inmediatamente después de enterarse de cualquier uso no autorizado de su Cuenta
                        o cualquier otra violación de seguridad.</p>
                      <p class="element">Ocasionalmente, los empleados de ClickDefense pueden acceder al
                        Servicio bajo su cuenta para mantener o mejorar el Servicio, incluyendo la
                        prestación de asistencia en cuestiones técnicas o de facturación. Por la
                        presente, el usuario reconoce y consiente dicho acceso.</p>
                    </ul>
                  </ul>
                  <!--Tercera parte-->
                  <ul>
                    <li id="title"> <strong>3. Licencia no exclusiva:</strong></li>
                    <ul id="table">
                      <p class="element">ClickDefense le concede por la presente una licencia limitada,
                        revocable, no exclusiva y no sublicenciable para instalar, copiar y utilizar el
                        Software únicamente en la medida en que sea necesario para utilizar el Servicio
                        para una o más páginas web que usted posea y controle (colectivamente, el "Sitio
                        Web"). Sujeto a los Términos y Condiciones de este Acuerdo, usted puede acceder
                        y ver de forma remota sus Informes almacenados en ClickDefense (o cualquier URL
                        que ClickDefense pueda proporcionar). Su licencia, uso y acceso al Software y al
                        Servicio (que puede incluir, sin limitación, el Software, la documentación y los
                        Informes) está condicionada al cumplimiento de los Términos y Condiciones de
                        este Acuerdo.</p>
                      <p class="element">Usted no lo hará, ni permitirá que lo haga ningún tercero: (i)
                        copiar, modificar, adaptar, traducir o crear de cualquier otra forma trabajos
                        derivados del Software o la Documentación; (ii) realizar ingeniería inversa,
                        descompilar, desensamblar o intentar descubrir de cualquier otra forma el código
                        fuente del Software; (iii) alquilar, arrendar, vender, asignar o transferir de
                        cualquier otra forma los derechos sobre el Código de Seguimiento, el Software de
                        Procesamiento, la documentación o el Servicio; (iv) eliminar cualquier aviso o
                        etiqueta de propiedad en el Software o colocado por el Servicio; o (v) utilizar,
                        publicar, transmitir o introducir cualquier dispositivo, software o rutina que
                        interfiera o intente interferir con el funcionamiento del Servicio o el
                        Software.</p>
                      <p class="element">Usted utilizará el Software, el Servicio y los Informes
                        únicamente para su propio uso interno. Cumplirá con todas las leyes y
                        regulaciones aplicables en su uso y acceso a la Documentación, el Software, el
                        Servicio y los Informes. La licencia expirará inmediatamente en caso de
                        incumplimiento de los términos de este Contrato. Tras dicho vencimiento, deberla
                        dejar de utilizar el Servicio sin el consentimiento expreso por escrito de
                        ClickDefense y destruir todos los originales y copias del Código de Seguimiento
                        en su posesión, certificar a ClickDefense por escrito que lo ha realizado dentro
                        de los 3 primeros días hábiles de finalización.</p>
                    </ul>
                  </ul>
                  <!--Cuarta parte-->
                  <ul>
                    <li id="title"> <strong>4. Derechos de información:</strong></li>
                    <ul id="table">
                      <p class="element">Por la presente, permite a ClickDefense recopilar, almacenar y
                        procesar sus Datos del Cliente. Los Datos del Cliente pueden incluir una o más
                        cookies que ClickDefense utiliza para diferenciar entre usuarios. ClickDefense
                        puede examinar los Datos del Cliente para proporcionarle soporte técnico o para
                        mejorar el Servicio.</p>
                      <p class="element">La recopilación y el procesamiento de sus Datos de Cliente
                        incluirán las direcciones IP utilizadas por el software para evitar el fraude de
                        clics. Estas direcciones IP pueden ser compartidas globalmente por los usuarios
                        del software. Las direcciones IP identificadas como Clic Fraudulento serán
                        almacenadas por nosotros de forma indefinida tras después de la finalización de
                        este acuerdo. Usted debe tener políticas de privacidad vigentes que reflejen esta
                        condición.</p>
                      <p class="element">Para mantener la integridad de nuestro software y su uso, usted
                        acepta que su sitio web funcionará de acuerdo con la GDPR y solo nos
                        proporcionará datos para procesar que hayan sido obtenidos de manera conforme.
                      </p>
                      <p class="element">ClickDefense puede conservar y utilizar, de acuerdo con los
                        términos de su Política de Privacidad (ubicada en
                        https://clickdefense.io/privacy, o cualquier otra URL que el Servicio pueda
                        proporcionar ocasionalmente), la información recopilada sobre su uso del
                        Servicio, para, entre otros, mejorar la experiencia de uso. ClickDefense no
                        compartirá información asociada con usted o su sitio web con terceros a menos
                        que: 1. cuente con su consentimiento; 2. concluya que es requerido por la ley o
                        crea de buena fe de que el acceso, la preservación o la divulgación de dicha
                        información es razonablemente necesaria para proteger los derechos, la propiedad
                        o la seguridad de ClickDefense, sus usuarios o el público; o 3. proporcione
                        dicha información en ciertas circunstancias limitadas a terceros para llevar a
                        cabo tareas en nombre de ClickDefense (por ejemplo, facturación o almacenamiento
                        de datos) con estrictas restricciones que impidan que los datos se utilicen o
                        compartan, excepto según lo indicado por ClickDefense.</p>
                      <p class="element">Cuando esto se hace, está sujeto a acuerdos que obligan a esas
                        partes a procesar dicha información solo en las instrucciones de ClickDefense y
                        en cumplimiento de este Acuerdo y medidas de confidencialidad y seguridad
                        apropiadas.</p>
                      <p class="element">A menos que notifique lo contrario a ClickDefense por escrito,
                        ClickDefense puede identificarlo como un cliente valioso y opcionalmente emitir
                        comunicaciones que revelen que está utilizando el Servicio, bien sea en prensa,
                        redes sociales o el propio sitio web de ClickDefense.</p>
                      <p class="element">Los derechos de información se protegerán y se harán cumplir en
                        línea con la GDPR a través del Oficial de Protección de Datos de ClickDefense
                        (ClickDefense S.L) asumiendo nosotros el coste total.</p>
                    </ul>
                  </ul>
                  <!--Quinta parte-->
                  <ul>
                    <li id="title"> <strong>5. Facturación, altas y bajas, cancelación:</strong></li>
                    <ul id="table">
                      <p class="element">Utilizamos Stripe como proveedor de pagos y servicios de
                        facturación. Al usar los servicios de dicho proveedor, usted acepta seguir y
                        cumplir con las políticas de dicho proveedor.</p>
                      <p class="element">Los servicios de ClickDefense pueden adquirirse mediante una
                        suscripción periódica en la que el pago del Servicio se realiza a mes vencido.
                        El servicio de suscripción se renovará automáticamente y se cargará en su
                        tarjeta de crédito (o en la cuenta de otro método de pago) todos los meses sin
                        necesidad de su autorización.</p>
                      <p class="element">Usted es responsable de pagar cualquier cuota e impuesto
                        aplicable asociado con el Servicio de manera oportuna con un método de pago
                        válido. A menos que se indique lo contrario, todas las tarifas se indican en
                        Euros (EUR). Usted acepta pagar por todos los servicios que adquiera a través
                        del Servicio, y podremos cobrar con el método de pago que haya seleccionado o
                        enviarle una factura por las tarifas adeudadas. El usuario debe mantener su
                        información de facturación actualizada, información actual, completa y precisa
                        (por ejemplo, un cambio en la dirección de facturación, en el número de la
                        tarjeta de crédito o en la fecha de caducidad) y notificar a ClickDefense si el
                        método de pago seleccionado se cancela (por ejemplo, por pérdida o robo).</p>
                      <p class="element">Usted es responsable de todos los cargos incurridos en su Cuenta,
                        así como de los cargos incurridos por Cuentas asociadas o creadas a través de su
                        Cuenta, ya sea por usted o cualquier persona que utilice su Cuenta (incluidos
                        sus compañeros de trabajo, colegas, miembros del equipo, etc.). Si su método de
                        pago falla o su tarjeta está caducada, podemos cobrar los cargos adeudadas
                        usando otros mecanismos de cobro. Su cuenta, así como las Cuentas asociadas con
                        o creadas a través de su Cuenta, pueden ser desactivadas sin previo aviso si el
                        pago está vencido, independientemente de la cantidad. Usted también es
                        responsable de pagar cualquier impuesto gubernamental que se aplique a su compra
                        o uso del Servicio, incluidos, entre otros, los impuestos sobre ventas, uso o
                        valor añadido.</p>
                      <p class="element">ClickDefense puede ofrecer diferentes niveles de servicio a
                        diferentes tarifas mensuales. En caso de que usted aumente o disminuya su nivel
                        de servicio, el precio del nivel de servicio elegido se cobrará en los
                        siguientes períodos mensuales.</p>
                      <p class="element">Si supera los límites de su nivel de servicio, su cuenta se
                        actualizará a un servicio nivel que se adapte a su uso, y el precio de este
                        nivel de servicio se cobrará en los períodos mensuales posteriores.</p>
                      <p class="element">Todas las cuotas se pagan a mes vencido y no son reembolsables.
                      </p>
                      <p class="element">Usted es el único responsable de cancelar correctamente su
                        cuenta. Puede cancelar su cuenta en cualquier momento comunicándose con
                        ClickDefense por correo electrónico en info@clickdefense.io. Su solicitud debe
                        ser recibida al menos 24 horas antes de su fecha de renovación de su
                        suscripción, o la cancelación no será efectiva hasta el mes siguiente.</p>
                      <p class="element">Todos los datos e informes de los clientes pueden ser eliminados
                        inmediatamente del Servicio al ser cancelados. Esta información no puede ser
                        recuperada una vez que su cuenta es cancelada.</p>
                      <p class="element">Si cancela su cuenta antes de que finalice el período de pago
                        actual, su cancelación entrará en vigor inmediatamente.</p>
                      <p class="element">ClickDefense a su exclusivo criterio, tiene derecho a
                        suspender o cancelar su Cuenta y rechazar cualquier uso actual o futuro del
                        Servicio. Dicha terminación del Servicio resultará en la desactivación o
                        eliminación de su Cuenta o su acceso a la misma, y la pérdida y renuncia de
                        todos los datos e informes del cliente en su Cuenta.</p>
                      <p class="element">Tras la cancelación, usted es el único responsable de la
                        eliminación de cualquier código de seguimiento ClickDefense que se han
                        instalado.</p>
                      <p class="element">Como parte de nuestro compromiso de proporcionar un servicio
                        excelente y fiable, ClickDefense aplica una política de uso justo a su servicio.
                        La política de uso razonable aplicada a todas las cuentas activas permite a
                        ClickDefense medir niveles de tráfico, ancho de banda, notificaciones por correo
                        electrónico y otras métricas de uso. En caso de que un cliente exceda o sea
                        resaltado como excedente de lo que se considera uso justo, el cliente será
                        contactado en primera instancia por correo electrónico. Nos reservamos el
                        derecho de cancelar cualquier cuenta que se considere que incumple lo que
                        ClickDefense define como uso razonable. ClickDefense normalmente proporcionará
                        una sugerencia alternativa para ese aumento de recursos que pueden atraer
                        mayores costes. ClickDefense nunca actualizará automáticamente su Cuenta.</p>
                    </ul>
                  </ul>
                  <!--Sexta parte-->
                  <ul>
                    <li id="title"> <strong>6. Indemnización:</strong></li>
                    <ul id="table">
                      <p class="element">Usted acepta indemnizar, eximir de responsabilidad y defender a
                        ClickDefense, a su cargo, de todas y cada una de las reclamaciones, acciones,
                        procedimientos y demandas de terceros presentada contra ClickDefense o
                        cualquiera de sus empleados, directores, agentes o afiliados, y todas las
                        responsabilidades daños, acuerdos, sanciones, multas, costos o gastos
                        relacionados (incluidos, entre otros, honorarios razonables de abogados y otros
                        gastos de litigio) incurridos por ClickDefense o cualquiera de sus empleados,
                        directores, agentes o afiliados, que surjan de o estén relacionados con (i) su
                        incumplimiento de cualquier término o condición de este Acuerdo, (ii) su uso del
                        Servicio, (iii) sus violaciones de las leyes, normas o regulaciones en relación
                        con el Servicio, o (iv) los Elementos de su Marca. En ese caso, ClickDefense le
                        proporcionará una notificación por escrito de dicha reclamación, demanda o
                        acción. Usted cooperará completamente como sea razonablemente requerido en la
                        defensa de cualquier reclamación. ClickDefense se reserva el derecho, a su
                        propio cargo, de asumir la exclusiva defensa y control de cualquier asunto
                        sujeto a indemnización por su parte. </p>
                    </ul>
                  </ul>
                  <!--Septima parte-->
                  <ul>
                    <li id="title"> <strong>7. Terceros:</strong></li>
                    <ul id="table">
                      <p class="element">Si proporciona acceso a su Cuenta o cualquier parte de la misma a
                        un tercero o utiliza el Servicio para recopilar información en nombre de un
                        tercero ("Tercero"), esté o no autorizado a hacerlo por ClickDefense, se
                        aplicarán los términos de esta Sección.</p>
                      <p class="element">Si utiliza el Servicio en nombre de un tercero, usted declara y
                        garantiza que (a) usted está autorizado para actuar en nombre de este Tercero y
                        cumplir con este Acuerdo, (b) entre el tercero y usted, el Tercero posee
                        cualquier derecho sobre los Datos del Cliente en la cuenta aplicable, y (c)
                        usted no revelará los Datos del Cliente del Tercero a ningún otra parte sin el
                        consentimiento del Tercero.</p>
                      <p class="element">Usted se asegurará de que cada tercero esté obligado y se atenga
                        a los términos de este Acuerdo. ClickDefense no hace ninguna representación o
                        garantías para el beneficio directo o indirecto de cualquier tercero. Con
                        respecto a terceros, usted tomará todas las medidas necesarias para renunciar a
                        todas y cada una de las las representaciones o garantías que puedan pertenecer a
                        ClickDefense, el Servicio, el Software o los Informes, o el uso de los mismos.
                        Usted acepta indemnizar, eximir de responsabilidad y defender ClickDefense, a su
                        cargo, contra todas y cada una de las reclamaciones, acciones, procedimientos y
                        demandas de terceros presentadas contra ClickDefense o cualquiera de sus
                        empleados, directores, agentes o afiliados, y todos las responsabilidades,
                        daños, acuerdos, sanciones, multas, costos o gastos relacionados (incluidos sin
                        limitación, entre otros, honorarios razonables de abogados y otros gastos de
                        litigio) incurridos por ClickDefense, o cualquiera de sus empleados, directores,
                        agentes o afiliados, que surjan de o estén relacionados con (a) cualquier
                        representación y garantía hecha por usted en relación con cualquier aspecto del
                        Servicio, el Software o los Informes a terceros; (b) cualquier reclamaciones
                        hechas por o en nombre de cualquier Tercero relacionada directa o indirectamente
                        con su uso del Servicio, el Software o los Informes; (c) las violaciones de sus
                        obligaciones de privacidad a cualquier Tercero; y (d) cualquier reclamación con
                        respecto a actos u omisiones de terceros en relación con los Servicios, el
                        Software o los Informes.</p>
                    </ul>
                  </ul>
                  <!--Octava parte-->
                  <ul>
                    <li id="title"> <strong>8. Descargo de responsabilidad de garantías:</strong></li>
                    <ul id="table">
                      <p class="element">La información y los servicios incluidos en el Servicio o disponibles
                        a través de él, incluidos los informes, pueden incluir imprecisiones o errores
                        tipográficos. Los cambios son periódicamente añadidos a la información aquí contenida.
                        ClickDefense y/o sus respectivos proveedores pueden realizar mejoras y/o cambios en
                        el Servicio o el Software en cualquier momento, con o sin previo aviso. </p>
                      <p class="element">ClickDefense no representa o garantiza que el Servicio será ininterrumpido
                        o libre de errores, que los defectos serán corregidos, o que el Servicio, el Software
                        o cualquier otro software en el Servidor están libre de virus u otros componentes
                        dañinos. Usted acepta específicamente que ClickDefense no será responsable del acceso
                        no autorizado o de la alteración de los Datos del Cliente o datos de su Sitio Web.
                        ClickDefense no garantiza el almacenamiento de los Datos del Cliente. El espacio de
                        almacenamiento que se le asigna está sujeto a un acuerdo con ClickDefense o a la
                        cantidad de espacio disponible.</p>
                      <p class="element">Algunos de los servicios pueden incluir la inserción automática de IP
                        en su lista de exclusión de Google Ads en sus campañas de Ads. ClickDefense no se
                        hace responsable de la pérdida de datos o daños de cualquier tipo como resultado de esto.</p>
                      <p class="element">ClickDefense no garantiza que el Servicio estará operativo en todo momento
                        o durante cualquier tiempo de inactividad (1) causado por interrupciones en cualquier red
                        troncal, red o servidor público de Internet, (2) causado por cualquier fallos de su equipo,
                        sistemas o servicios de acceso local, (3) por mantenimiento programado previamente o (4)
                        relacionado con eventos más allá del control de ClickDefense como huelgas, disturbios,
                        insurrecciones, incendios, inundaciones, explosiones, guerras, acciones gubernamentales,
                        condiciones laborales, terremotos, desastres naturales o interrupciones en los servicios
                        de Internet en un área donde ClickDefense o sus servidores están localizados o co-ubicados.
                        Tampoco se garantiza exactitud en todos los aspectos de sus estadísticas en todo momento.</p>
                    </ul>
                  </ul>
                  <!--Novena parte-->
                  <ul>
                    <li id="title"> <strong>9. Descargo de responsabilidad por daños:</strong></li>
                    <ul id="table">
                      <p class="element">Nada en estos Términos y Condiciones excluye o limita la responsabilidad
                        de ClickDefense o del Usuario por fraude (incluyendo tergiversación fraudulenta u ocultación),
                        incumplimiento de contrato, incumplimiento intencional, negligencia o cualquier otra
                        responsabilidad que no pueda ser legalmente excluida o limitada.</p>
                      <p class="element">Cualquier responsabilidad derivada de un incumplimiento de estos
                        Términos y Condiciones se limitará a cualquier pérdida o daño que sea una consecuencia
                        razonablemente previsible de dicho incumplimiento y que surja directamente de las acciones
                        de la parte que incumple. Ninguna de las partes será responsable por ninguna pérdida de
                        beneficios, negocios de ventas o ingresos.</p>
                      <p class="element">ClickDefense no es responsable ante el Usuario por cualquier pérdida o daño,
                        ya sea de naturaleza contractual o extracontractual (incluida la negligencia),
                        incumplimiento de obligaciones legales o de otro tipo, que surjan de o en relación
                        con su uso o incapacidad para usar el software; uso o dependencia de cualquier contenido
                        que se muestre en la aplicación, excepto cuando la responsabilidad surge como resultado
                        de nuestro incumplimiento de contrato, negligencia o falta.</p>
                      <p class="element">ClickDefense utiliza técnicas estándar de la industria para proteger su sitio
                        web y su aplicación libres de virus y ataques, pero no puede garantizar que estén libres de
                        virus u otro software malicioso. Ni ClickDefense ni cualquiera de sus terceros proveedores
                        serán responsables por ningún perjuicio, pérdida o daño, ya sea directo, indirecto, especial,
                        imprevisto o consecuente, ni por ningún lucro cesante, pérdida de contratos, pérdida de datos
                        o programas, o por el coste de recuperar dichos datos o programas, incluso si hubieran sido
                        informados de la posibilidad de los citados daños por anticipado. </p>
                      <p class="element">Cualquier responsabilidad por parte del Usuario está limitada al total de los
                        importes pagados por el servicio contratado. Esta limitación será de aplicación a todo derecho
                        a iniciar acciones, incluidos, con carácter enunciativo pero no limitativo, por incumplimiento
                        contractual, incumplimiento de garantía, negligencia, responsabilidad objetiva, representación
                        errónea de la realidad (salvo que sea dolosa) y otros actos ilícitos extracontractuales.</p>
                      <p class="element">Ni ClickDefense ni cualquiera de sus terceros proveedores serán responsables
                        por ningún perjuicio, pérdida o daño, ya sea directo, indirecto, especial, imprevisto o consecuente,
                        ni por ningún lucro cesante, pérdida de contratos, pérdida de datos o programas, o por el coste
                        de recuperar dichos datos o programas, incluso si hubieran sido informados de la posibilidad
                        de los citados daños por anticipado.</p>
                      <p class="element">ClickDefense no será responsable de ningún fraude de clics
                        detectado o no detectado por el Software. El software hace el mejor esfuerzo
                        para identificar múltiples clics de anuncios desde el mismo sistema informático
                        en un corto período de tiempo, y para identificar aumentos anómalos en la
                        actividad de los clics. El Servicio notificará al Cliente, a través de informes
                        en línea y, en algunos casos, correos electrónicos, de tales identificaciones.
                        El Servicio no impedirá que se produzca el fraude de clics, ni detectará todos
                        los tipos y todos los casos de fraude de clics que puedan producirse.</p>
                    </ul>
                  </ul>
                  <!--Decima parte-->
                  <ul>
                    <li id="title"> <strong>10. Avisos de derechos de propiedad:</strong></li>
                    <ul id="table">
                      <p class="element">El Servicio, que incluye, entre otros, el Código de Seguimiento,
                        el Software y todos los derechos de propiedad intelectual en el Servicio son, y
                        seguirán siendo, propiedad de ClickDefense. Todos los derechos sobre el Software
                        que no se otorgan expresamente en el presente Acuerdo están expresamente
                        reservados y retenidos por ClickDefense y sus licenciatarios sin restricción,
                        incluyendo, sin limitación, el derecho de propiedad del Software y logotipos,
                        marcas comerciales, secretos comerciales, bases de datos, informes y Sitio Web.
                      </p>
                      <p class="element">Sin limitar la generalidad de lo anterior, usted se compromete a
                        no (y a no permitir que ningún tercero lo haga): (a) sublicenciar, distribuir o
                        utilizar el Servicio fuera del alcance de la Licencia otorgada en este
                        documento; (b) copiar, modificar, adaptar, traducir, preparar trabajos
                        derivados, realizar ingeniería inversa, desmontar o descompilar el Software de
                        procesamiento o intentar descubrir cualquier código fuente o secretos
                        comerciales relacionados con el Servicio; (c) utilizar las marcas comerciales,
                        nombres comerciales, marcas de servicio, logotipos, nombres de dominio y otras
                        características de marca distintiva o de cualquier derecho de autor u otros
                        derechos de propiedad asociados con el Servicio para cualquier propósito sin el
                        consentimiento expreso por escrito de ClickDefense; (d) registrar, intentar
                        registrar o ayudar a cualquier otra persona a registrar cualquier marca
                        comercial, nombre comercial, marcas del servicio, logotipos, nombres de dominio
                        y otras características distintivas de la marca, derechos de autor u otros
                        derechos de propiedad asociados con ClickDefense que no sean en nombre de
                        ClickDefense; o (e) eliminar, ocultar o alterar cualquier aviso de derechos de
                        autor, marca registrada, u otro derecho de propiedad que aparezca en cualquier
                        elemento incluido con el Servicio.</p>
                    </ul>
                  </ul>
                  <!--Onceava parte-->
                  <ul>
                    <li id="title"> <strong>11. Modificaciones a los Términos y Condiciones del Servicio y
                        Otras Políticas:</strong></li>
                    <ul id="table">
                      <p class="element">ClickDefense se reserva el derecho de actualizar en cualquier
                        momento y de vez en cuando, modificar o interrumpir, de forma temporal o
                        permanentemente, el Servicio (o cualquier parte del mismo) en cualquier momento
                        y de vez en cuando, con o sin previo aviso. ClickDefense no será responsable
                        ante usted ni ante ningún tercero por cualquier modificación, cambio de precio,
                        suspensión o interrupción del Servicio.</p>
                      <p class="element">Cualquier nueva característica que aumente o mejore el Servicio
                        actual, incluyendo el lanzamiento de nuevas herramientas y recursos, estará
                        sujeta a los Términos y Condiciones de Servicio. La continuación del uso del
                        Servicio después de cualquiera de estos cambios constituirá su consentimiento a
                        dichos cambios. Puede revisar la versión más reciente de los Términos y
                        Condiciones del Servicio en cualquier momento en: http://www.clickdefense.io/terms-and-conditions.html.
                      </p>
                      <p class="element">Bajar el nivel de servicio puede causar la pérdida de datos,
                        características o capacidad de su Cuenta. ClickDefense no acepta ninguna
                        responsabilidad por tal pérdida.</p>
                    </ul>
                  </ul>
                  <!--Doceava parte-->
                  <ul>
                    <li id="title"> <strong>12. Condiciones generales:</strong></li>
                    <ul id="table">
                      <p class="element">El uso del Servicio es bajo su propio riesgo. El Servicio se
                        proporciona "tal cual" y "según esté disponible".</p>
                      <p class="element">El soporte técnico solo se proporciona a los titulares de cuentas
                        de pago y solo está disponible a través de correo electrónico o del servicio de
                        asistencia.</p>
                      <p class="element">No debe modificar, adaptar o piratear el Servicio o modificar
                        otro sitio web para dar a entender falsamente que está asociado al Servicio, a
                        ClickDefense o cualquier otro Servicio de ClickDefense.</p>
                      <p class="element">Usted acepta no reproducir, duplicar, copiar, vender, revender o
                        explotar ninguna parte del Servicio, el uso del Servicio o el acceso al Servicio
                        sin el permiso expreso por escrito de ClickDefense.</p>
                      <p class="element">Usted entiende que el procesamiento técnico y la transmisión del
                        Servicio, incluyendo sus Datos, pueden ser transferidos e implican (a)
                        transmisiones a través de varias redes; y (b) cambios para cumplir y adaptarse a
                        los requisitos técnicos de conexión redes o dispositivos de conexión.</p>
                      <p class="element">El personal técnico de ClickDefense puede indicarle, de vez en
                        cuando, que realice modificaciones al Código de Seguimiento instalado en su
                        sitio web. Usted acepta cooperar y realizar cualquier modificación requerida.
                      </p>
                      <p class="element">Usted otorga a ClickDefense una licencia perpetua, no
                        exclusiva, mundial, libre de derechos de autor, para utilizar y mostrar su
                        nombre, el nombre de su empresa, la dirección de su sitio web, la dirección del
                        sitio web de su empresa y el logotipo de su empresa en sitios web de
                        ClickDefense y materiales de marketing y listas de clientes de ClickDefense</p>
                      <p class="element">No se hacen realizan garantías expresas o implícitas de ingresos
                        al acceder a el Servicio. Usted acepta que no somos responsables del éxito o el
                        fracaso de sus decisiones comerciales.</p>
                    </ul>
                  </ul>
                  <!--Treceava parte-->
                  <ul>
                    <p id="title"> <strong>13. Ley aplicable y lugar:</strong></p>
                    <ul id="table">
                      <p class="element">ClickDefense será eximido de actuar en virtud de este
                        documento en la medida en que la actuación se vea impedida, retrasada u
                        obstruida por causas ajenas a su control razonable. Si alguna disposición de
                        este Acuerdo se considera inaplicable por cualquier razón, dicha disposición se
                        reformará en la medida necesaria para que sea aplicable en la medida máxima
                        permitida de modo que afecte a la intención de las partes, y el resto de este
                        Acuerdo continuará en pleno vigor y efecto.</p>
                      <p class="element">El presente Acuerdo se regirá e interpretará de conformidad con
                        las leyes de España sin referencia a sus principios de conflicto de leyes. En
                        caso de conflicto entre leyes, normas y reglamentos extranjeros, y leyes, normas
                        y reglamentos de España, las leyes, normas y reglamentos Europeas prevalecerán y
                        regirán. Cada parte acuerda someterse a la jurisdicción exclusiva y personal de
                        los tribunales ubicados en Ávila, España. La relación entre ClickDefense y Usted
                        es una relación de contratistas independientes. El presente Acuerdo será
                        vinculante y se aplicará a beneficio de los respectivos sucesores y cesionarios
                        de las partes del presente.</p>
                    </ul>
                  </ul>
                  <!--Contacto-->
                  <ul style="padding: 10px; text-align:center">
                    <p id="title"> <strong>Contacto</strong></p>
                    <p class="element">Si tiene alguna pregunta o consulta sobre nosotros, nuestro sitio,
                      nuestros servicios, nuestra política o estos Términos y Condiciones, contáctenos por
                      correo electrónico a info@clickdefense.io.</p>
                    <p class="element"><strong>Última actualización: 04 / 02 / 2021 </strong></p>
                  </ul>
                </td>
              </tr>
            </table>
          </div>
          <div class="scrolldowncheck">
            <input type="checkbox" v-model="termsStep3Checked" @change="validateStep3">
            <span> Al marcar esta casilla, indicas que has leído y aceptas los Términos y Condiciones.</span>
          </div>
        </div>

        <div data-test="sign-up-footer-section" class="Section__sectionFooter___1QlDn">
          <span class="Section__sectionFooterPrimaryContent___36Gb1">
            <button data-test="sign-up-create-account-button" class="Button__button___1FrBC btn-continue" @click="nextStep3" v-bind:class="{butdisabled:!validStep3Form}">Registrar</button>
          </span>
        </div>
      </div>
    </div>
    <!-- Step 4 - Confirm Clickdefense email: -->
    <div v-if="registerStep==='4'" data-test="sign-in-section" class="Form__formSection___3tqxz">
      <div data-test="sign-in-header-section" class="Section__sectionHeader___13iO4">¡Ya casi está!</div>
      <div class="form-help-text text-center">
        <p>Confirma que eres humano en el enlace del correo electrónico que te hemos enviado y entrarás directamente al panel de control.</p>
      </div>
      <div data-test="sign-in-footer-section" class="Section__sectionFooter___4QlDn">
        <span class="Section__sectionFooterSecondaryContent___3cjOa"><span class="font-weight-bold">¿No has recibido el correo?</span> Mira en tu carpeta spam o <a data-test="sign-in-create-account-link" class="Anchor__a___3JUCG font-weight-bold">Solicítanos otro enlace</a></span>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: 'MyComponent',
  props: [],
  data() {
    return {
      registerStep: '1',
      termsStep3Checked: false,
      gAccountInput: '',
      nameInput: '',
      emailInput: '',
      passwordInput: '',
      validStep1Form: false,
      validStep2Form: false,
      validStep3Form: false,
      environmentId: '',
      nAttempts: 0,
      showModalStep2: false,
      inpErrEmail: false,
      actionLnk: ''
    }
  },
  methods: {
    nextStep1() {
      if (this.validStep1Form) {
        this.$vs.loading()
        // INI Send gAccount
        const gAccParams = {
          gAccount: this.gAccountInput,
          environmentId: this.environmentId
        }
        this.$axios.post('/lucn1rvdt6jwwglv9f6n2k6szv7m5qxjnvvr8bhe3turaygf0pycrfs1cj00ct7y', gAccParams, {}).then(response => {
          this.$vs.loading.close()
          if (response.data && response.data.msg && response.data.msg === 'OK') {
            const gAcc = JSON.stringify({ 'n0hjRm8G80c6mvuSuQbG': this.gAccountInput })
            localStorage.setItem('session', Buffer.from(gAcc).toString('base64'))
            this.registerStep = '2'
            setTimeout(() => { this.validStep2Form = true }, 30000);
            this.$gtm.push({ event: 'Registro_Paso1_Completado' })
          } else {
            this.nAttempts++
            this.registerStep = '2a'
          }
        })
        // FIN Send gAccount
        //localStorage.setItem('gAccount', this.gAccountInput)
      }
    },
    nextStep2() {
      if (this.validStep2Form) {
        this.$vs.loading()
        // INI Check gAccount
        const gAccParams = {
          gAccount: this.gAccountInput,
          environmentId: this.environmentId
        }
        this.$axios.post('/fr8i03ypei7ugk64cdwokjpc350taj7gbs6ltgnnmaqof5pg451a8x22aim60taj', gAccParams, {}).then(response => {
          this.$vs.loading.close()
          if (response.data && response.data.msg && response.data.msg === 'OK') {
            this.registerStep = '3'
            this.$gtm.push({ event: 'Registro_Paso2_Completado' })
          } else {
            this.showModalStep2 = true
          }
        })
        // FIN Check gAccount
      }
    },
    nextStep2a() {
      this.registerStep = '1'
    },
    async nextStep3() {
      if (this.validStep3Form) {
        try {
          const user = await Auth.signUp({
            username: this.emailInput,
            password: this.passwordInput,
            attributes: {
              email: this.emailInput,
              name: this.nameInput
            }
          })
          if (user && user.userSub) {
            const cdSession = localStorage.getItem('session')
            if (cdSession && cdSession !== '') {
              try {
                const cdSessionObj = JSON.parse(atob(cdSession))
                const acc = cdSessionObj['n0hjRm8G80c6mvuSuQbG']
                if (acc) {
                  localStorage.removeItem('session')
                  const uData = {
                    '9s75CYh37vyEpUHSJJmO': this.emailInput,
                    'nvk5IPDf8xCRrMOZial1': this.passwordInput,
                    'lHjY5TwQcSV4G4U4KXIS': user.userSub
                  }
                  const gAcc = JSON.stringify(uData)
                  localStorage.setItem('session', Buffer.from(gAcc).toString('base64'))
                  // INI Create user
                  this.$vs.loading()
                  let userParams = {
                    'ceEvgZAChCLwF8h08SKj': this.emailInput,
                    'waRMG5mG0s64kcps3DYX': this.nameInput,
                    'jLo5xy4J7lAK1QN8qESN': user.userSub,
                    'rNtqomz7LHg1qJW5HvR5': this.gAccountInput
                  }
                  if (this.actionLnk !== '' || this.actionLnk !== '0') {
                    userParams['sOo5WpFnM8IToF4eRQiR'] = this.actionLnk
                  }
                  this.$axios.post('/em73ujd6ahoflnkwkxgprf6aq37p1xqv4x7de4knnmtvtffgjxgnm94ynv3d6lux', userParams, {}).then(response => {
                    this.$vs.loading.close()
                    if (response.data && response.data.msg && response.data.msg === 'OK') {
                      this.registerStep = '4'
                      // eslint-disable-next-line no-undef
                      Froged('set', { email: this.emailInput, })
                      this.$gtm.push({ event: 'Registro_Paso3_Completado' })
                    } else {
                      this.showModalStep3 = true
                    }
                  })
                  // FIN Create user
                }
              } catch (err) {
                console.log('')
              }
            }
          }
        } catch (error) {
          if (error && typeof (error) === 'object') {
            if (error.code === 'UsernameExistsException') {
              this.inpErrEmail = true
            }
          }
        }
      }
    },
    btnAlreadyCdAcc() {
      this.$router.push({ path: '/auth' })
    },
    validateStep1() {
      if (this.gAccountValid(this.gAccountInput)) {
        this.validStep1Form = true
      } else {
        this.validStep1Form = false
      }
    },
    validateStep3() {
      if (this.emailValid(this.emailInput) && this.passwordValid(this.passwordInput) && this.nameValid(this.nameInput) && this.termsStep3Checked) {
        this.validStep3Form = true
      } else {
        this.validStep3Form = false
      }
    },
    emailValid(dataEmail) {
      const re = /\S+@\S+\.\S+/
      return re.test(dataEmail);
    },
    passwordValid(dataPassword) {
      const re = /[0-9a-zA-Z@#$%^&+!=]{8,}/
      return re.test(dataPassword);
    },
    nameValid(dataName) {
      const re = /^([a-zA-Z ]){2,30}$/
      return re.test(dataName);
    },
    gAccountValid(dataGAccount) {
      const re = /^([0-9]{3}-[0-9]{3}-[0-9]{4})$/
      return re.test(dataGAccount);
    },
    registerOK() {
      this.$vs.notify({
        time: 10000,
        title: 'Registrado correctamente',
        text: 'Puedes acceder al panel de control',
        color: 'success',
        iconPack: 'feather',
        icon: 'icon-check',
        position: 'top-center'
      })
    },
  },
  mounted() {
    this.actionLnk = this.$route.params.actionLnk
  },
  created() {
    this.$loadScript("https://jpcbjmccwkfr2orrrgmu3zlee5lzws.s3-eu-west-1.amazonaws.com/ueokfyrb6djj2c6n.js")
      .then(() => {
        if (window.requestIdleCallback) {
          requestIdleCallback(() => {
            // eslint-disable-next-line
            GetEnvironment.get((components) => {
              const envVal = components.map((component) => { return component.value })
              // eslint-disable-next-line
              const environmentId = GetEnvironment.x64hash128(envVal.join(''), 31)
              this.environmentId = environmentId
            })
          })
        } else {
          setTimeout(() => {
            // eslint-disable-next-line
            GetEnvironment.get((components) => {
              const envVal = components.map((component) => { return component.value })
              // eslint-disable-next-line
              const environmentId = GetEnvironment.x64hash128(envVal.join(''), 31)
              this.environmentId = environmentId
            })
          }, 500)
        }
      })
  }
}                          
</script>
<style scoped>
#app > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
#app > div > div:nth-child(2) {
  margin: 0 auto;
}
.logo-container {
  width: 100%;
  text-align: center;
  margin: 1% auto;
}
</style>
<style>
.texthead {
  font-weight: bold;
}
.textdisabled {
  color: gainsboro;
}
.butdisabled {
  background-color: rgba(125, 78, 197, 0.5) !important;
}
.textar {
  width: 100%;
  margin: 10px 0px;
}
.textar > div {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}
#conditions {
  height: 250px;
  text-align: justify;
  margin: 1% 0;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}
.Form__formSection___3tqxz {
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  width: 35vw;
  min-width: 360px;
  padding: 20px;
}
@media (max-width: 1280) {
  .Form__formSection___3tqxz {
    width: 50vw;
  }
}
.Section__sectionBody___3DCrX {
  margin-bottom: 0;
}
.Section__sectionHeader___13iO4 {
  text-align: center;
  margin-bottom: 3rem;
  font-weight: 800;
}
.scrolldowncheck {
  margin-top: 20px;
}
.error {
  color: #ff0000;
}
.backclick {
  background-image: url(../../assets/images/pages/bg-login.png);
  background-size: cover;
  min-height: 100vh;
  font-size: 1.1rem;
}
.Input__inputLabel___nQolz span {
  color: #737373;
  margin-left: 20px;
}
.form-help-text p {
  color: #313131;
  margin-bottom: 3rem;
}
.form-steps-list li span {
  color: #313131;
  margin-left: 1rem;
}
.form-steps-list li {
  margin-bottom: 10px;
}
.btn-continue {
  background-color: rgb(125, 78, 197);
  float: right;
  outline: none !important;
}
.btn-back {
  background-color: rgb(125, 78, 197);
  float: left;
  outline: none !important;
}
.input-format-msg {
  font-size: 0.9rem;
  margin-left: 10px;
}
.form-steps-center {
  text-align: center;
}
.modal-header {
  color: #ffffff;
  background-color: #e4dfff;
}
.modal-button {
  background-color: rgb(125, 78, 197);
  border-color: rgb(125, 78, 197);
}
.modal-body strong {
  margin-right: 10px;
}
.modal-body li {
  margin-bottom: 10px;
}
.input-error {
  border: #ff0000 solid 1px;
}
.backButton {
  margin: 2px auto;
}
.Anchor__a___3JUCG {
  color: #3366bb !important;
}
.Anchor__a___3JUCG:hover {
  text-decoration-line: underline !important;
}
.centerButton {
  margin: 0 auto !important;
}
.imgLogInCD {
  width: 65%;
  max-width: 469px;
}
.textGAccCont {
  text-align: center;
}
.GAccContTitle {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 15px;
}
.lessSpaceSignUp {
  margin-bottom: 1rem !important;
}
.greenContainer {
  background-color: rgba(32, 255, 0, 0.59);
  color: white;
}
</style>